if(document.readyState !== 'loading') {   
    initLazyLoad();
} else {
    document.addEventListener('DOMContentLoaded', function () {        
        initLazyLoad();
    });
}

function initLazyLoad() { 
    var srcSets = [].slice.call(document.querySelectorAll(".lazy > source"));
    var srcSetImgs = [].slice.call(document.querySelectorAll(".lazy > img"));

    var images = [].slice.call(document.querySelectorAll("img.lazyimage"));
    var iframes = [].slice.call(document.querySelectorAll("div.lazyiframe"));
    
    if ("IntersectionObserver" in window) {
        var srcSetObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    var srcSet = entry.target;
                    srcSet.srcset = srcSet.dataset.srcset;
                    srcSet.nextElementSibling.srcset = srcSet.dataset.srcset;
                    srcSet.parentElement.classList.remove("lazy");
                    srcSetObserver.unobserve(srcSet);
                }
            });
        });

        var imageObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    var img = entry.target;
                    img.src = img.dataset.src;
                    img.classList.remove("lazyimage");
                    imageObserver.unobserve(img);
                }
            });
        });

        var iframeObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    var iframe = entry.target;
                    iframe.innerHTML = iframe.dataset.html;
                    iframe.classList.remove("lazyiframe");
                    iframeObserver.unobserve(iframe);
                }
            });
        });

        srcSets.forEach(function (srcSet) {
            srcSetObserver.observe(srcSet);
        });

        images.forEach(function (image) {
            imageObserver.observe(image);
        });

        iframes.forEach(function (iframe) {
            iframeObserver.observe(iframe);
        });
    } else {
        // Not supported, load all images immediately
        srcSets.forEach(function (srcSet) {
            srcSet.parentElement.classList.remove("lazy");
            srcSet.src = srcSet.dataset.srcset;
            srcSet.nextElementSibling.srcset = srcSet.dataset.srcset;
        });
        srcSetImgs.forEach(function (image) {
            image.src = image.dataset.src;
        });

        images.forEach(function (image) {
            image.src = image.dataset.src;
        });

        iframes.forEach(function (iframe) {
            iframe.innerHTML = iframe.dataset.html;
        });
    }
}
