var swiper = new Swiper('.swiper-container.travel', {
    loop: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
});

var swiperRelated = new Swiper('.related-content-wrapper .swiper-container', {
    loop: false,
    slidesPerView: 1,
    centeredSlides: false,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        // when window width is <= 499px
        768: {
            slidesPerView: 2,
        }
    }
});
