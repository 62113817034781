//(function ($) {
//    var defaultOptions = {
//        validClass: '',
//        errorClass: 'is-invalid'
//    };

//    $.validator.setDefaults(defaultOptions);

//    $.validator.unobtrusive.options = {
//        errorClass: defaultOptions.errorClass,
//        validClass: defaultOptions.validClass
//    };

//    // https://stackoverflow.com/a/52159728/1693918
//    //$.validator.setDefaults({
//    //    errorElement: 'span',
//    //    errorPlacement: function (error, element) {
//    //        error.addClass('invalid-feedback');
//    //        element.closest('.form-group').append(error);
//    //    },
//    //    highlight: function (element, errorClass, validClass) {
//    //        $(element).addClass('is-invalid');
//    //    },
//    //    unhighlight: function (element, errorClass, validClass) {
//    //        $(element).removeClass('is-invalid');
//    //    }
//    //});

//})(jQuery);

$(function () {
    $(".order-trip").click(function () {
        var tripName = $(this).data("trip-name");
        var formField = $(this).parents(".card").siblings(".modal").find("form").find(".tripname").find("input.hidden");

        $(formField).val(tripName);
    });

    if (window.location.href.indexOf('#modal-') != -1) {
        var id = window.location.hash;
        $(id).modal('show');
    }
});
