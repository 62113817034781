$('.travel-calendar.archive .nav li a').click(function (e) {     
    //get selected href
    var href = $(this).attr('href');    
    
    //set all nav tabs to inactive
    $('.travel-calendar.archive .nav li a').removeClass('active');

    //active tab
    $('.travel-calendar.archive .tab-pane').removeClass('show active');
    $('.travel-calendar.archive .tab-pane' + href).addClass('show active');

    //get all nav tabs matching the href and set to active
    //$('.nav li a[href="' + href + '"]').addClass('active');
    $('.travel-calendar.archive .nav li a[href="' + href + '"]').addClass('active');
})

$(".pills-bottom a").click(function () {
    $('html,body').animate({ scrollTop: 0 }, 'fast');
    return false;
});
